import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toMonth, toDay, toDayOfWeekNumberOnly } from '../../../utils/date';
import { getOption } from '../../../utils';
import { getURLSearchParamByKey } from '../../../utils';
import { t } from 'src/i18n/config';

const Container = styled.div`
  margin-top: 16px;
  border: ${(props) => props.theme.boxBorder.active};
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

const DateString = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.textSecondary};
`;
const MainSection = styled.div`
  margin-top: 6px;
  margin-bottom: 8px;
  height: 106px;
  font-size: 1rem;
  width: 100%;
  display: flex;
`;
const LeftSection = styled.div`
  width: 50%;
  border-right: ${(props) => props.theme.boxBorder.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const RightSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const DoctorName = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.07px;
`;
const Subject = styled.div`
  margin-top: 8px;
`;
const PatientNumberTitle = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  line-height: 1.54;
  letter-spacing: 0.09px;
`;
const PatientNumberValue = styled.div`
  margin-top: 8px;
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.primary};
`;
const Waiting = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.textSecondary};
`;
const WaitingNumber = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

function ProgressCard({ appointment }) {
  const {
    date,
    time,
    doctorName,
    subject,
    room,
    currentSeq,
    waitingCount,
    updatedAt,
  } = appointment;
  const isMainpi = getOption() === 'mainpi';
  const hideWaitingInfo = getURLSearchParamByKey('hideWaitingInfo');
  return (
    <Container>
      <DateString>
        {`${toMonth(date)} ${t('month')} ${toDay(date)} ${t(
          'day'
        )} (${toDayOfWeekNumberOnly(date)})`}{' '}
        {time}
      </DateString>
      <MainSection>
        <LeftSection>
          <DoctorName>{t('doctor', { name: doctorName })}</DoctorName>
          <Subject>
            {subject} {room}
          </Subject>
        </LeftSection>
        <RightSection>
          {currentSeq && updatedAt && (
            <React.Fragment>
              <PatientNumberTitle>{t('currentNumber')}</PatientNumberTitle>
              <PatientNumberValue>
                {currentSeq} {t('number')}
              </PatientNumberValue>
              {!hideWaitingInfo &&
                waitingCount != null &&
                waitingCount !== 0 &&
                !isMainpi && (
                  <Waiting>
                    {t('waitingCount')}:{' '}
                    <WaitingNumber>{waitingCount}</WaitingNumber> {t('people')}
                  </Waiting>
                )}
            </React.Fragment>
          )}
        </RightSection>
      </MainSection>
    </Container>
  );
}

ProgressCard.defaultProps = {
  appointment: {},
};

ProgressCard.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    relation: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    time: PropTypes.string,
    doctorName: PropTypes.string,
    subject: PropTypes.string,
    room: PropTypes.string,
    currentNumber: PropTypes.number,
    number: PropTypes.number,
    waiting: PropTypes.number,
    cancelable: PropTypes.bool,
  }),
};

ProgressCard.defaultProps = {
  availables: [],
};
export default ProgressCard;
