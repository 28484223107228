import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RefreshButton } from '../1TalkDesign/Buttons';

const Container = styled.div`
  width: 100%;
`;
const RefreshSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const RefreshContent = styled.div`
  flex: auto;
  font-family: NotoSansCJKtc;
  font-size: 1rem;
  line-height: 1.54;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.primary};
`;
function Refresh({ title, onClick }) {
  return (
    <Container>
      <RefreshSection>
        <RefreshContent>{title}</RefreshContent>
        <RefreshButton onClick={onClick}></RefreshButton>
      </RefreshSection>
    </Container>
  );
}

Refresh.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default Refresh;
