import React from 'react';
import styled from 'styled-components';
import Loading from '../../1TalkDesign/Loading';
import ProgressCard from './ProgressCard';
import Menu from '../../1TalkDesign/menu';
import Refresh from '../Refresh';
import { t } from 'src/i18n/config';

const Container = styled.div`
  margin: 32px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SubjectMenu = styled.div`
  margin-top: 16px;
  width: 100%;
`;
const CMenu = styled(Menu)`
  background-color: white;
`;

const Empty = styled.div`
  margin-top: 46px;
  width: 100%;
  text-align: center;
`;

function ProgressPage({
  appointments,
  subjects,
  selectedSubject,
  loading,
  onRefresh,
  onSelect,
}) {
  const render = () => {
    if (loading) {
      return (
        <Empty>
          <Loading color="rgb(0, 109, 177)"></Loading>
        </Empty>
      );
    }
    if (appointments.length === 0) {
      return <Empty>{t('noProgress')}</Empty>;
    }
    return appointments.map((appointment) => {
      return (
        <ProgressCard
          key={appointment.id}
          appointment={appointment}
        ></ProgressCard>
      );
    });
  };
  return (
    <Container>
      <Refresh onClick={onRefresh} title={t('actualProgress')}></Refresh>
      <SubjectMenu>
        <CMenu items={subjects} name={selectedSubject} onClickItem={onSelect} />
      </SubjectMenu>
      {render()}
    </Container>
  );
}

ProgressPage.propTypes = {};
ProgressPage.defaultProps = {
  appointments: [],
};

export default ProgressPage;
