import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash-es';
import Layout from 'components/layout';
import AppointmentsProgressPage from 'components/appointments/progress/ProgressPage';
import { fetchProgress } from 'services/appointment.service';
import useMainpi from 'src/customHooks/mainpi';
import { getDataSource } from 'src/utils';
import { t } from 'src/i18n/config';

async function fetchData({ selectedSubject: targetSubject }) {
  const data = await fetchProgress();
  const as = Object.keys(data).reduce((obj, current) => {
    // filter 88888 ticket: https://www.notion.so/88888-6b85a3fbb16a4eed96153b56d621a988
    const filteredData = data[current].filter((d) => d.currentSeq !== '88888');
    if (filteredData.length) {
      obj[current] = filteredData;
    }
    return obj;
  }, {});
  const subjects = Object.keys(as).map((s) => ({ id: s, name: s, value: s }));
  const selectedSubject = targetSubject || Object.keys(as)[0];
  if (subjects.length === 0) {
    return {
      as,
      subjects,
      selectedSubject: undefined,
    };
  }
  return {
    as,
    subjects,
    selectedSubject,
  };
}

const AppointmentsPage = () => {
  const [appointmentsBySubject, setAppointmentsBySubject] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  useEffect(() => {
    fetchData({}).then(({ as, subjects, selectedSubject }) => {
      setSubjects(subjects);
      setSelectedSubject(selectedSubject);
      setAppointmentsBySubject(as);
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    debounce(() => {
      setAppointmentsBySubject(undefined);
      fetchData({ selectedSubject }).then(
        ({ as, subjects, selectedSubject }) => {
          setSubjects(subjects);
          setSelectedSubject(selectedSubject);
          setAppointmentsBySubject(as);
        }
      );
    }, 300),
    []
  );

  const handleSelect = (item) => {
    setAppointmentsBySubject(undefined);
    fetchData({ selectedSubject: item.value }).then(
      ({ as, subjects, selectedSubject }) => {
        setSubjects(subjects);
        setSelectedSubject(selectedSubject);
        setAppointmentsBySubject(as);
      }
    );
  };

  const appointments = appointmentsBySubject
    ? appointmentsBySubject[selectedSubject]
    : undefined;
  const { mainpiCodes, loading: mainpiLoading } = useMainpi(
    (appointments || [])
      .filter((a) => a.waitingCode !== '')
      .map((a) => a.waitingCode)
  );

  if (appointments) {
    appointments.forEach((a) => {
      // sometimes mainpi is still responded but visionweb is stalled, in this case we follow visionweb
      if (getDataSource() === 'vs' && a.currentSeq === '尚未開診') {
        return;
      }
      const call = mainpiCodes[a.waitingCode];
      if (call) {
        a.currentSeq = call.num;
        a.updatedAt = call.lastUpdate;
      }
    });
  }
  return (
    <Layout>
      <Helmet>
        <title>{t('departmentProgress')}</title>
      </Helmet>
      <AppointmentsProgressPage
        subjects={subjects}
        selectedSubject={selectedSubject}
        appointments={appointments}
        loading={appointmentsBySubject == null || mainpiLoading}
        onRefresh={handleRefresh}
        onSelect={handleSelect}
      ></AppointmentsProgressPage>
    </Layout>
  );
};

export default AppointmentsPage;
